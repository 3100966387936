import {FrontendSharedApi} from '@shared/api/definitions/frontend_shared_api';
import {ApiMetadata} from '@shared/api/model';

export interface Aoe2ImageInfo {
  src: string;
  width: number;
  height: number;
}

export interface Aoe2Details {
  title?: string;
  sections: Aoe2DetailsSection[];
}

export interface Aoe2DetailsSection {
  title?: string;
  lines: Map<string, Aoe2DetailsSectionLine[][]>;
}

export interface Aoe2Image {
  src: string;
  width: number;
  height: number;
}

export type Aoe2DetailsSectionLine = string | Aoe2Image;

export const Aoe2Api: ApiMetadata<
  {
    '/details': {
      req: {path: string};
      res: Aoe2Details;
    };
  } & FrontendSharedApi
> = {isFrontend: true};
