import {FrontendName} from '@shared/frontends/frontend_constant';
import {createTheme} from '@shared/frontends/theme_context';

import {baseTheme} from '@src/components/theme_base';

export const BASE_FONT = '"Nunito Sans", sans-serif';

export const theme = createTheme(FrontendName.AgeOfEmpires, baseTheme, {
  logo: (
    <svg viewBox="30.86 0.63 56.91 70.45">
      <path
        fill="#C30"
        d="M66.5 27.5v.17h.24m10.85 0h.28v-.33m0-20.64v-.26c0-1.6 1.99-2.9 4.4-2.9h5.5v-.4c0-1.38-1.15-2.51-2.56-2.51H33.42a2.54 2.54 0 0 0-2.56 2.5v.24h5.69c2.32 0 4.2 1.31 4.2 2.91v59.79c0 1.6-1.88 2.9-4.2 2.9H30.9a2.56 2.56 0 0 0 2.52 2.1h51.8c1.26 0 2.32-.9 2.52-2.1h-5.46c-2.42 0-4.4-1.3-4.4-2.9V6.69zM52.14 23.16c.03-.05.05-.1.05-.16V6.91c0-.04 0-.08-.02-.11a4.29 4.29 0 0 1 4.28-3.75h5.74a4.3 4.3 0 0 1 4.26 3.65v20.64c0 .06.02.12.04.17v.16h.24l-.24.3v36.46a4.27 4.27 0 0 1-4.3 4.22h-5.74c-2.37 0-4.3-1.9-4.3-4.22V23.16z"
      />
    </svg>
  ),
});
