// Part of the theme that does not import react so we can use it in the backend
// with the file `frontend_theme_registry_no_react.ts`

import {textColor} from '@shared/frontends/frontend_theme_utils';

export const BASE_FONT = '"Nunito Sans", sans-serif';

export const baseTheme = {
  main: {
    backgroundColor: '#272d2f',
    textColor: '#ffffff',
    accentColor: '#cc330044',
    accentTextColor: '#ffffff',
    lineHeight: 1.7,
  },
  button: {
    backgroundActive: '#ffffff22',
    backgroundDisabled: '#ffffff11',
    backgroundHover: '#ffffff33',
    backgroundLoading: '#ffffff11',
  },
  link: {
    ...textColor('lightBlue'),
  },
  input: {
    backgroundColor: '#ffffff11',
    backgroundColorHover: '#ffffff16',
    backgroundColorFocus: '#ffffff16',
    backgroundColorDisabled: '#ffffff33',
    textColorDisabled: '#00000066',
    borderWidth: 3,
    borderColor: 'transparent',
    borderRadius: 8,
    focusBorderColor: '#cc330044',
    focusBorderWidth: 3,
    focusOutlineWidth: 0,
    fontSize: 16,
    textColor: '#ffffff',
  },
  textarea: {
    backgroundColor: '#ffffff11',
    backgroundColorHover: '#ffffff16',
    backgroundColorFocus: '#ffffff16',
    backgroundColorDisabled: '#ffffff33',
    textColorDisabled: '#00000066',
    borderWidth: 3,
    borderColor: 'transparent',
    borderRadius: 8,
    focusBorderColor: '#cc330044',
    focusBorderWidth: 3,
    focusOutlineWidth: 0,
    fontSize: 16,
    textColor: '#ffffff',
  },
};
