import styled from 'styled-components';

import {Custom} from '@shared-frontend/lib/react';

import {LightText} from '@src/components/fragments';

interface CivTileProps {
  label: string;
  imgUrl: string;
}

export const CivTile: Custom<CivTileProps, 'div'> = props => {
  const {label, imgUrl, ...rest} = props;
  return (
    <Wrapper {...rest}>
      <Icon src={imgUrl} />
      <Name>{label}</Name>
    </Wrapper>
  );
};
CivTile.displayName = 'CivTile';

const Wrapper = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
  margin: 0 8px;
`;
const Name = styled(LightText)`
  font-size: 24px;
`;
