import {FC} from 'react';
import {Route, Routes} from 'react-router';

import {FrontendName} from '@shared/frontends/frontend_constant';

import {useRoute} from '@shared-frontend/components/core/use_route';
import {useSharedRoutes} from '@shared-frontend/components/shared_routes';

import {AppWrapper} from '@src/components/app_wrapper';
import {CivPage} from '@src/components/civ_page';
import {DetailsPage} from '@src/components/details_page';
import {UnitsPage} from '@src/components/units_page';

export const App: FC = () => {
  const seo = {
    title: 'AoE2',
    description: 'Compare AoE2 civilizations',
  };

  return (
    <Routes>
      {useSharedRoutes<FrontendName.AgeOfEmpires>({
        seo,
        appWrapper: AppWrapper,
        userDataForm: undefined,
      })}
      <Route path="/:firstCiv/:secondCiv" element={useRoute([AppWrapper, CivPage], seo)} />
      <Route path="/details/:encodedPath" element={useRoute([AppWrapper, DetailsPage], seo)} />
      <Route path="/units" element={useRoute([AppWrapper, UnitsPage], seo)} />
      <Route path="*" element={useRoute([AppWrapper, CivPage], seo)} />
    </Routes>
  );
};
App.displayName = 'App';
