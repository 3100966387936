import {useContext} from 'react';

import {DataStoreApi, DataStoreContext} from '@shared/frontends/data_store_api';
import {FrontendDataStore} from '@shared/frontends/frontend_data_store';

import {createDataStore} from '@shared-frontend/lib/data_store';

type FrontendDataStoreApi<T extends keyof FrontendDataStore> = {
  [K in keyof FrontendDataStore[T]]: DataStoreApi<FrontendDataStore[T][K], FrontendDataStore[T][K]>;
};

export function createUseDataStore<
  T extends keyof FrontendDataStore,
>(): () => FrontendDataStoreApi<T> {
  return () => {
    const dataStoreContext = useContext(DataStoreContext);
    if (!dataStoreContext) {
      throw new Error('DataStoreContext is missing');
    }
    return Object.fromEntries(
      Object.entries(dataStoreContext).map(([key, value]) => [key, createDataStore(value)])
    ) as unknown as FrontendDataStoreApi<T>;
  };
}
