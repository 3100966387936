import {FC, Fragment, JSX} from 'react';
import styled from 'styled-components';

import {AdminBar} from '@shared-frontend/components/admin_bar';
import {useScreenSize} from '@shared-frontend/lib/use_screen_size';

interface AppWrapperProps {
  children: JSX.Element;
}

const MIN_WIDTH = 512;

export const AppWrapper: FC<AppWrapperProps> = props => {
  const {children} = props;
  const screenSize = useScreenSize();
  return (
    <Fragment>
      <AdminBar mode="button" />
      <AdjustZoom $zoom={Math.min(1, screenSize / MIN_WIDTH)}>{children}</AdjustZoom>
    </Fragment>
  );
};
AppWrapper.displayName = 'AppWrapper';

const AdjustZoom = styled.div<{$zoom: number}>`
  zoom: ${p => p.$zoom};
`;
