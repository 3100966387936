import {FC, MouseEventHandler, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {CivTile} from '@src/components/civ_tile';
import {CivViewer} from '@src/components/civ_viewer';
import {useDataStore} from '@src/components/store';

interface AppHalfProps {
  currentCiv: string;
  onCivChange: (newCiv: string) => void;
}

export const AppHalf: FC<AppHalfProps> = props => {
  const {currentCiv, onCivChange} = props;
  const civs = useDataStore().civs.useData();
  const civ = useMemo(() => civs.find(c => c.name === currentCiv), [civs, currentCiv]);

  const handleCivClick = useCallback<MouseEventHandler>(
    e => onCivChange(e.currentTarget.getAttribute('data-civ') ?? 'pick'),
    [onCivChange]
  );
  const handleDismiss = useCallback(() => onCivChange('pick'), [onCivChange]);

  return (
    <Wrapper>
      {civ === undefined ? (
        civs.map(c => (
          <CivTile
            data-civ={c.name}
            key={c.name}
            onClick={handleCivClick}
            label={c.name}
            imgUrl={c.imgUrl}
          />
        ))
      ) : (
        <CivViewer civ={civ} onDismiss={handleDismiss} />
      )}
    </Wrapper>
  );
};
AppHalf.displayName = 'AppHalf';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: min-content;
`;
