import {useEffect, useState} from 'react';

import {useSsrContext} from '@shared/frontends/use_ssr_context';

export const useScreenSize = (): number => {
  const {initialScreenWidth} = useSsrContext();
  const [screenSize, setScreenSize] = useState(initialScreenWidth);
  useEffect(() => {
    if (!IS_BROWSER) {
      return;
    }
    const handleResize = (): void => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return screenSize;
};
