import {FC} from 'react';
import styled from 'styled-components';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Image} from '@shared-frontend/components/core/image';

import unitsIconPng from '@src/images/units_icon.png';

interface UnitsIconProps {}

export const UnitsIcon: FC<UnitsIconProps> = () => {
  return (
    <Wrapper to="/units">
      <Image alt="units" srcAndSizes={{srcData: unitsIconPng, width: 48}} width={48} />
    </Wrapper>
  );
};

UnitsIcon.displayName = 'UnitsIcon';

export const Wrapper = styled(UnthemedNavLink)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 16px;
  background: #ffffff20;
  border-radius: 48px;
`;
